/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ConnectWallet from "components/ConnectWallet";
import useStorage from "utils/useStorage";
import { useThemeDispatch } from "states/themeContext";
import { useEffect } from "react";
import { useGlobalDispatch, useGlobalState } from "states/globalContext";
import WalletOptions from "./WalletOptions";
import { useQuery } from "@tanstack/react-query";
import useSigner from "utils/useSigner";
import Image from "ui-components/Image";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Password } from "./password";
import { useTeleSwap } from "services/useTeleSwap";
import usePrice from "services/api/usePrice";
import { isTestnet, projectName } from "utils/configs";
import MenuList from "./MenuList";
import classNames from "classnames";

export default function Header() {
    const { walletOptions, isAuthenticated } = useGlobalState();
    const themeDispatch = useThemeDispatch();
    const globalDispatch = useGlobalDispatch();
    // const { theme } = useThemeState();
    const { setItem, getItem } = useStorage();
    useSigner();
    const priceMethod = usePrice();
    const teleSwap = useTeleSwap();

    const [searchParams, setSearchParams] = useSearchParams();
    const referralAddressParam = searchParams.get("referralAddress");

    const links: {
        index: any;
        key: string;
        value: any;
        content?: any;
    }[] = isTestnet
        ? [
              {
                  index: 0,
                  key: "/bridge",
                  value: <span className="flex  gap-1 items-center">Bridge</span>,
                  content: "",
              },
              { index: 1, key: "/transaction-history", value: "My history", content: "" },
              { index: 2, key: "/network-stats", value: "Network stats", content: "" },
          ]
        : [
              {
                  index: 0,
                  key: "/bridge",
                  value: <span className="flex  gap-1 items-center">Bridge</span>,
                  content: "",
              },
              {
                  index: 1,
                  key: "/staking",
                  value: (
                      <span className="flex  gap-1 items-center">
                          Staking
                          {/* <span className="bg-core px-2  py-1  rounded-lg card-shine-effect"> New: TST!</span> */}
                      </span>
                  ),
                  content: "",
              },
              // {
              //     index: 2,
              //     key: "/farming",
              //     value: (
              //         <span>
              //             Farming
              //             {/* <span className="bg-core px-1 py-[2px]  rounded-lg">Boosted TST</span> */}
              //         </span>
              //     ),
              //          content: "",
              // },

              // {
              //     index: 3,
              //     key: "/referral-stats",
              //     value: <span>Referral</span>,
              //          content: "",
              // },
              { index: 2, key: "/transaction-history", value: "My history" },
              { index: 3, key: "/network-stats", value: "Network stats" },
          ];
    const location = useLocation();
    const pathname = location?.pathname;
    const activeHomeIndex = (pathname === "/" ? 0 : links.find((i) => i.key.includes(pathname))?.index) || 0;

    useEffect(() => {
        const theme = getItem("user_theme", "local");
        theme && setItem("user_theme", theme, "local");
        theme && themeDispatch({ type: "setTheme", value: theme });
        // const defaultBTCWallet = window.localStorage.getItem("DEFAULT_BTC_WALLET") || "liquality";
        // const isAuthBTCWallet =
        //     window.localStorage.getItem("USER_BTC_WALLET") === "true" && !defaultBTCWallet.includes("trezor")
        //         ? true
        //         : false;
        // const userPermission = window.localStorage.getItem("USER_BTC_DISCONNECTED_WALLET");

        // if ((userPermission === null || Boolean(userPermission) === false) && !defaultBTCWallet.includes("trezor")) {
        //     isAuthBTCWallet && bitcoinMethod.connectWallet(defaultBTCWallet);
        // }
        globalDispatch({
            type: "setReferralAddress",
            value: referralAddressParam,
        });
    }, []);

    useQuery({
        queryKey: ["btcPrice"],
        queryFn: () => {
            return priceMethod.getTokenPrice("BTC");
        },
        refetchInterval: false,
        refetchOnWindowFocus: false,
        retry: false,
    });

    useQuery({
        queryKey: ["nativeTokenBTCRate"],
        queryFn: () => {
            return priceMethod.getNativeTokenBTCRate();
        },
        refetchInterval: false,
        refetchOnWindowFocus: false,
        retry: false,
    });

    useQuery({
        queryKey: ["getEnableStats"],
        queryFn: () => {
            return teleSwap.getEnableStats();
        },
        refetchInterval: false,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const Link = ({ children, href, className = "", target = "", disabled = false, activeClassName }: any) => {
        return (
            <NavLink
                to={href}
                replace={false}
                className={({ isActive }) =>
                    isActive
                        ? `${className} ${disabled ? "disabled" : ""} ${activeClassName}`
                        : `${className} ${disabled ? "disabled" : ""} `
                }
                target={target}
                rel="noopener noreferrer"
            >
                {children}
            </NavLink>
        );
    };
    const navigate = useNavigate();
    const handleChange = (index: number) => {
        navigate(`${links[index].key}`);
    };

    return (
        <header className="flex justify-between items-center w-full pt-2 md:py-4  md:max-w-[1400px]  ">
            {/* <Image
                src={`/images/${theme === "light" ? "moon" : "sun"}.svg`}
                width="30"
                className=" mx-2 cursor-pointer"
                height="10"
                alt="arrow"
                loading="lazy"
                onClick={() => {
                    setItem("user_theme", theme === "light" ? "dark" : "light", "local");
                    themeDispatch({ type: "toggleTheme" });
                }}
            /> */}

            <div className="flex items-center ">
                <Link className="flex items-center md:min-h-[92px] text-primary pl-4 md:pl-0 " href={"/"}>
                    {/* <Image
                        src={`/images/${projectName === "investor" ? "tst" : "full_logo_bright"}.png`}
                        width={`${projectName === "investor" ? "68" : "362"}`}
                        height={`${projectName === "investor" ? "53" : "80"}`}
                        alt="arrow"
                        loading="lazy"
                        className={`md:-mt-2 ${projectName === "investor" ? "w-12" : "w-36"}`}
                    /> */}
                    <Image
                        src={`/images/${"full_logo_bright"}.png`}
                        width={`${"362"}`}
                        height={`${"80"}`}
                        alt="arrow"
                        loading="lazy"
                        // className={`md:-mt-2 ${projectName === "investor" ? "w-12" : "w-36"}`}
                        className={`md:-mt-2 ${"w-36"}`}
                    />
                    {/* {projectName === "investor" ? (
                        <h1 className="px-3 md:px-5 -mt-3  text-base md:text-2xl ">Teleport System Token</h1>
                    ) : null} */}
                </Link>
            </div>

            {projectName !== "investor" && (
                <ul className="md:flex gap-4 items-center max-w-[max-content] pt-1 pb-4 md:pb-0 space-x-1 px-2 md:px-0 rounded-t-lg overflow-auto hidden ">
                    {links.map((item) => (
                        <li
                            onClick={() => handleChange(item.index)}
                            key={item.key}
                            className={classNames(
                                ` cursor-pointer  pb-1 whitespace-nowrap  hover:text-primary text-base  font-medium ${"rounded-t-lg   leading-5 "} `,
                                "focus:outline-none  ring-primary ",
                                activeHomeIndex === item.index
                                    ? `${"text-primary border-b-2 border-primary"}`
                                    : `${"text-gray-lighter"}`,
                            )}
                        >
                            <Link href={item.key}>{item.value}</Link>
                        </li>
                    ))}
                </ul>
            )}

            {walletOptions && <WalletOptions open={walletOptions.show} />}
            {/* {!isAuthenticated && <Password />} */}
            <div className="flex items-center gap-2 mt-8 md:mt-0">
                <ConnectWallet type="button" />
                <MenuList />
            </div>
        </header>
    );
}
