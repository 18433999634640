export default function Image({ ...res }) {
    return (
        <img
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = currentTarget?.currentSrc?.includes(".COM")
                    ? "/images/colorful/dotcom.png"
                    : (currentTarget.src = currentTarget?.currentSrc?.includes("usdc.e.png")
                          ? "https://statics.teleportdao.xyz/coin/USDC.png"
                          : (currentTarget.src = currentTarget?.currentSrc?.includes("WBNB")
                                ? "https://statics.teleportdao.xyz/coin/BNB.png"
                                : currentTarget?.currentSrc));
            }}
            {...res}
            src={
                res?.src?.includes("WTST.png")
                    ? "https://statics.teleportdao.xyz/coin/TST.png?"
                    : res?.src?.includes("TST.png")
                      ? "https://statics.teleportdao.xyz/coin/TST.png?"
                      : res?.src?.includes("ethereum.png") || res?.src?.includes("ETHEREUM.png")
                        ? "https://statics.teleportdao.xyz/network/ethereum.png"
                        : res?.src?.includes("TST(TEST).png")
                          ? "https://statics.teleportdao.xyz/coin/TST(Test).png"
                          : res?.src?.includes("base.png") || res?.src?.includes("BASE.png")
                            ? "https://statics.teleportdao.xyz/network/base.png"
                            : res?.src?.includes("polygon.png") || res?.src?.includes("POLYGON.png")
                              ? "https://statics.teleportdao.xyz/network/polygon.png"
                              : res?.src?.includes("arbitrum.png") || res?.src?.includes("ARBITRUM.png")
                                ? "https://statics.teleportdao.xyz/network/arbitrum.png"
                                : res?.src?.includes("binance.png") || res?.src?.includes("BINANCE.png")
                                  ? "https://statics.teleportdao.xyz/network/bsc.png"
                                  : res?.src?.includes("optimism.png") || res?.src?.includes("OPTIMISM.png")
                                    ? "https://statics.teleportdao.xyz/network/optimism.png"
                                    : res?.src?.includes("bsquared.png") || res?.src?.includes("BSQUARED.png")
                                      ? "https://statics.teleportdao.xyz/network/bsquared.png"
                                      : res?.src?.includes("bob.png") || res?.src?.includes("BOB.png")
                                        ? "https://statics.teleportdao.xyz/network/bob.png"
                                        : res?.src?.includes("R.png")
                                          ? "https://statics.teleportdao.xyz/coin/DOG.png"
                                          : res?.src?.includes("BTCB.png")
                                            ? "https://statics.teleportdao.xyz/coin/BTC.png"
                                            : res?.src?.includes("colorful/BTC.png")
                                              ? "https://statics.teleportdao.xyz/coin/BTC.png"
                                              : res?.src?.includes("BITCOIN.png")
                                                ? "https://statics.teleportdao.xyz/coin/BTC.png"
                                                : res?.src?.includes("MEME.png")
                                                  ? "/images/colorful/meme.png"
                                                  : res?.src?.includes("TRIO.png")
                                                    ? "/images/colorful/trio.png"
                                                    : res?.src?.includes(".COM.png")
                                                      ? "/images/colorful/dotcom.png"
                                                      : res?.src?.includes("usdc.e.png")
                                                        ? "https://statics.teleportdao.xyz/coin/USDC.png"
                                                        : res?.src?.includes("WBNB")
                                                          ? "https://statics.teleportdao.xyz/coin/BNB.png"
                                                          : res?.src
            }
        />
    );
}
